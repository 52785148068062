#login_page {
  min-height: 100%;
  height: 100%;
  background-color: var(--white-lilac);
  min-width: 100%;
  background: url(../images/kosta-bratsos-lBPgzz3HGIo-unsplash.jpg) no-repeat top center;
  flex-direction: column;
  background-size: cover;
  position: relative;
}

#login_page::before {
  content: '';
  background: rgba(0, 165, 217, 0.8);
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
}
