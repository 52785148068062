.chat {
  border-radius: 4px;
  background: #E9FAFFFF;
  box-sizing: border-box;
  border: 1px solid #D9D9D9FF;
}

.titleWrap {
  padding: 10px;
  background-color: #F2F2F2FF;
  border-bottom: 1px solid #D9D9D9FF;
}

.chatIcon {
  width: 22px;
  vertical-align: middle;
}

.title {
  margin-left: 1rem;
}

.ChatWrap {
  height: 400px;
  display: flex;
  margin: 25px 10px;
  flex-flow: column wrap;
  justify-content: space-between;
}

.messageWrap {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
}

.messageWrap::-webkit-scrollbar {
  width: 6px;
}

.messageWrap::-webkit-scrollbar-track {
  background: #DDDDDDFF;
}

.messageWrap::-webkit-scrollbar-thumb {
  background: #BDBDBDFF;
}

.message {
  display: flex;
  margin-top: .25rem;
  align-items: flex-end;
  margin-bottom: .25rem !important;
}

.messageHeader {
  display: flex;
  padding: 10px;
  color: #666666FF;
  background: #EEEEEEFF;
  justify-content: space-between;
  border-bottom: 2px solid #DDDDDDFF;
}

.message:last-of-type {
  margin: 0;
}

.messageImage {
  width: 50px;
  height: 50px;
  display: flex;
  flex-wrap: wrap;
  margin-right: 10px;
  border-radius: 50%;
  align-items: center;
  background: #FFFFFFFF;
}

.messageBubble {
  padding: 10px;
  max-width: 450px;
  border-radius: 15px;
  background: #FFFFFFFF;
  border: 1px solid #C4C4C4FF;
}

.messageText {
  font-size: 14px;
}

.messageLeft .messageBubble {
  border-bottom-left-radius: 0;
}

.messageRight {
  flex-direction: row-reverse;
}

.messageRight .messageBubble {
  color: #FFFFFFFF;
  background: #00A5D9FF;
  border: 1px solid #037599FF;
  border-bottom-right-radius: 0;
}

.messageRight .messageBubbleOrange {
  color: #FFFFFFFF;
  background: #E98300FF;
  border: 1px solid #E98300FF;
  border-bottom-right-radius: 0;
}

.messageBubbleOrange {
  padding: 10px;
  max-width: 450px;
  border-radius: 15px;
  background: #FFFFFFFF;
  border: 1px solid #C4C4C4FF;
}

.messageRight .messageImage {
  margin: 0 0 0 10px;
}

.messageInfo {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
}

.messageInfoName {
  font-size: 10px;
  color: #00A5D9FF;
  margin-right: 10px;
}

.messageInfoNameRight {
  font-size: 10px;
  color: #FFFFFFFF;
  margin-right: 10px;
}

.messageInfoTime {
  font-size: 0.85em;
}

.botPerson {
  width: 27px;
  height: 40px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  vertical-align: middle;
}
